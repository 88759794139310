#DiagnosisList {
  width: 100%;
  height: 100%;
}

input {
  width: 100%;
}

.form-check .form-check-input {
  margin-left: 0;
  border: 1px solid var(--color-dark-grey-alpha);
  cursor: pointer;
}

.exacerbation-check {
  display: flex;
  justify-content: center;
  align-items: center;
}

.expandable-list {
  position: absolute;
  z-index: 12;
  background-color: var(--color-light-bg);
}

.expand-btn-holder {
  position: sticky;
  top: 100%;
  z-index: 15;
  width: 100%;
  height: 0;
}

.expand-btn {
  position: absolute;
  top: -2.75rem;
  right: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

/* dropdown */
.dropdown-menu {
  z-index: 1;
  max-height: 5.6rem;
  overflow-y: auto;
}

.dropdown-item:active {
  background-color: initial;
  color: initial;
}

.dropdown_btn.btn {
  padding: 6px 0px;
  border: none;
}

.dropdown_btn.btn:hover svg {
  fill: var(--color-primary-blue);
}
/* /dropdown */
