:root {
  /* colors */
  --color-white: #fff;
  --color-bg: #d9ecff;
  --table-head-bg: aliceblue;
  --color-light-bg: #f8f9fa;
  --color-block-header: #2f4f4f;
  --color-green-bg: #b0ffda;
  --color-green-preoperative: #198754;
  --color-input-group-bg: #e9ecef;
  --color-table-border: #ced4da;
  --color-input-group-text: #212529c7;
  --color-dark-grey: #232526;
  --color-border-grey: #41464b;
  --color-dark-grey-alpha: rgba(0, 0, 0, 0.8);
  --color-primary-red: #c01616;
  --color-primary-blue: #0d6efd;
  --color-secondary-blue: #2684ff;
  --color-blue-light-1: #deebff;
  --color-blue-light-2: #b2d4ff;
  --row-hover-bg: #3891672b;
}
