.UsefulBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
}

.useful-btn,
.useful-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.useful-btn {
  width: 46px;
  height: 38px;
  padding: 0;
}

.useful-btn:not(:last-child) {
  margin-right: 5px;
}

.useful-btn svg {
  fill: currentColor;
}
