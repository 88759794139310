#MainPage {
  text-align: center;
  width: 100%;
  height: 100vh;
  padding: 50px 0 0;
}

/* Textarea block*/
.text-block {
  position: relative;
}

.textarea-container {
  width: 100%;
  height: 32%;
  padding-top: 5px;
  padding-bottom: 2px;
}

.textarea-container > .col-md-4 {
  padding-right: 1rem;
}

textarea {
  display: block;
  width: 100%;
  height: 100%;
  resize: none;
  line-height: 1.4;
}

.input-group {
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
}

.input-group-text {
  border-radius: 0;
  padding: 0.5rem 0.75rem;
  color: var(--color-input-group-text);
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  white-space: initial;
  background-color: var(--color-input-group-bg);
}

.form-control {
  border-radius: 0;
}

.form-control.text-box {
  width: 100%;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0px;
  margin-top: -1px;
}
/* /Textarea block */

/* blocks */
.row {
  margin-right: 0;
  margin-left: 0;
}

.row > * {
  padding-right: 0;
  padding-left: 0;
}

.btn-row {
  padding-bottom: 1rem;
}

.table-container {
  width: 100%;
  height: 58%;
  padding-top: 5px;
  padding-bottom: 2px;
}

.column {
  height: 100%;
  padding-left: 0;
}

.list {
  overflow-y: scroll;
  height: 100%;
}

#main-block {
  width: 100%;
  height: 90%;
}

.tables-block {
  height: 100%;
  position: relative;
}

.button-block {
  width: 100%;
  height: 10%;
}

.default-block {
  width: 100%;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.expandable-wrapper {
  position: relative;
}

.table__bordered {
  border: 1px solid var(--color-table-border);
}
/* /blocks */

/* decoration, action */
.result-btn-decline {
  margin-left: 1rem;
}

.action-button svg {
  fill: currentColor;
}

.action-icon {
  display: flex;
  padding: 0;
  border: none;
  background: transparent;
  justify-content: center;
  align-items: center;
}

.action-icon:hover {
  background: transparent;
}

.action-icon svg {
  fill: var(--color-dark-grey);
  transition: fill 250ms ease-in-out;
}

.action-icon.action-icon__trash:hover svg {
  fill: var(--color-primary-red);
}

.action-icon.action-icon__copy:hover svg,
.action-icon.action-icon__eye:hover svg {
  fill: var(--color-primary-blue);
}

/* /decoration, action */

/* Tooltip */
.tooltip-inner {
  max-width: 350px;
}

.tooltip-approve .tooltip-inner {
  max-width: 140px;
}
/* / Tooltip */

/* preoperative */
.green-bg {
  background-color: var(--color-green-bg);
}

.preoperative-btn {
  position: relative;
  padding: 0;
  width: 46px;
  height: 38px;
}

.preoperative-btn::after {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 1;
}

.preoperative-btn:hover {
  color: var(--color-green-preoperative);
  background-color: transparent;
  border-color: var(--color-green-preoperative);
}

.preoperative-btn.active:hover {
  color: var(--color-white);
  background-color: var(--color-green-preoperative);
  border-color: var(--color-green-preoperative);
}
/* /preoperative */

/* Media Queries */
@media screen and (min-width: 1440px) {
  #MainPage {
    padding: 64px 0 0;
  }

  #main-block {
    height: 92%;
  }

  table,
  .input-group-text {
    font-size: 1rem;
  }

  .table-container {
    height: 61%;
  }

  .button-block {
    height: 7%;
  }
}
