.BlockHeader {
  position: absolute;
  top: -1.25rem;
  width: 100%;
  height: 0px;
  padding: 0 1rem;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: var(--color-block-header);
}

/* Media Queries */

@media screen and (min-width: 1440px) {
  .BlockHeader {
    top: -1.5rem;
    font-size: 12px;
  }
}
