#AuthPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90%;
  padding: 10rem 1rem 0;
}

.auth-heading {
  font-size: calc(1.375rem + 0.75vw);
}

.auth-form {
  width: 450px;
  margin-top: 3rem;
}

.form-floating > label {
  opacity: 0.6;
  padding: 0.75rem;
}

.form-floating > .form-control {
  border-radius: 0.25rem;
  height: calc(3rem + 2px);
  line-height: 1;
  padding: 0.75rem;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.8) translateY(-0.65rem) translateX(0.15rem);
}

@media screen and (min-width: 1440px) {
  #AuthPage {
    padding: 14rem 1rem 0;
  }
}
