body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg);
  overflow: hidden;
}

body,
html {
  width: 100%;
  height: 100vh;
}

#root {
  height: 100%;
}

td {
  vertical-align: middle;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

/* select */
.custom-select__value-container {
  text-align: left;
}
