.CustomContentContainer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.CustomContentContainer.main_content {
  height: 100%;
  max-width: 1920px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.CustomContentContainer.header_content {
  height: fit-content;
  position: fixed;
  top: 0;
  z-index: 11;
}
