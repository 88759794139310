.custom-option {
  transition: background-color 60ms;
}

.custom-option.custom-select__option:hover {
  transition-delay: 60ms;
  background: var(--color-blue-light-1);
}

.custom-option.custom-select__option:active {
  background: var(--color-blue-light-2);
}

.custom-option.custom-select__option--is-focused {
  background: none;
}

.custom-option.custom-select__option--is-selected,
.custom-option.custom-select__option--is-selected:hover {
  background: var(--color-secondary-blue);
}
