table {
  width: 100%;
  font-size: 14px;
}

.table > :not(:first-child) {
  border-top: none;
}

.table tbody tr {
  height: 50px;
}

.table tbody tr:nth-child(n + 4) .custom-select__menu {
  bottom: 40px;
  top: auto;
  z-index: 100;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 50px;
  vertical-align: middle;
  line-height: 1.2;
  background-color: var(--table-head-bg);
}

.table > thead::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 1px;
  background: var(--color-border-grey);
  width: 100%;
  height: 1px;
}

.table-sm tbody tr td {
  padding: 0.5rem;
}

table > tbody > tr:hover > td,
table > tbody > tr:focus-within > td {
  background-color: var(--row-hover-bg);
}

.table > :not(caption) > * > * {
  color: var(--color-dark-grey);
  background-color: inherit;
}
